import React from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { AppContext, NotFoundComponent } from "../../App";
import * as Yup from "yup";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { MyForm, MyFormCheck, MyFormControl, MySelect, OnValueChangeArgument, SelectOption, MyMaskedFormControl, MyTextarea, MySelectConOptionABM } from "../../FormikHooks";
import { Button, Col, Form, Tab, Tabs, Alert, Modal } from "react-bootstrap";
import { Switch, Route } from "react-router-dom";
import { useDecodedParams, convertirDatosGenericosAOption, isNullOrWhiteSpace, optionLabelConCodigo, toFixedDecimal, toHtmlDate } from "../../Utilidades";
import { Decimal } from "decimal.js";
import { MyModal } from "../../MyModal";
import { TipoCampo, GrillaSync } from "../../Grilla";
import { DialogoConfirmar, DialogoConfirmarRef } from "../../DialogoConfirmar";
import { ModalImportadores } from "Paginas/Importadores/Importadores";
import { RutasVendedor } from "Paginas/Vendedores/Vendedores";
import { RutasDespachante } from "Paginas/Despachantes/Despachantes";
import InnerRouter from "InnerRouter";
import styled from "styled-components";
import { useRedirectEventCallback } from "SintiaHooks";
import { TipoLock, useApi } from "ApiHooks";
import BlockUi from "react-block-ui";

function fleteEsOpcional(subregimen: string | null | undefined) {
    return !isNullOrWhiteSpace(subregimen) && (subregimen === 'SIMI' || subregimen === 'SIRA'  || subregimen === 'SEDI' ||
        subregimen!.startsWith('EC') || subregimen!.startsWith('EG'));
}
const seguroEsOpcional = fleteEsOpcional;

enum EstadoCargando {
    CargaInicial,
    Cargando,
    Listo
}

const StyledFormCheck = styled(MyFormCheck)`
    display:inline-block;
    margin-left:0.5rem;
`;

// estos son los info comp que nose mostraran en el combo al agregar en caratula
const filterInfoComp = [
    'SIMI-DESC-MERC01',
    'SIMI-DESC-MERC02',
    'SIMI-DESC-MERC03',
    'SIMI-DESC-MERC04',
    'SIMI-DESC-MERC05',
    'SIMI-DESC-MERC06',
    'SIMI-DESC-MERC07',
    'SIMI-DESC-MERC08',
    'SIMI-DESC-MERC09',
    'SIMI-DESC-MERC10',
    'SIMI-PLAZO-GIRO',
    'SIMI-FECHA-ARRIB',
    'FECHA INIC.ACTIV',
    'DOMICIL.ESTABLEC',
    'IDTRIB-PROVEEDOR'
];



export function EditarCaratula() {
    let formikRef = React.useRef<FormikProps<any>>(null);
    let formikFacturasRef = React.useRef<FormikProps<any>>(null);
    let formikInfCompRef = React.useRef<FormikProps<any>>(null);
    let formikCodigosProrrateoRef = React.useRef<FormikProps<any>>(null);
    let dialogoRef = React.useRef<DialogoConfirmarRef>(null);
    let { interno } = useDecodedParams() as { interno: string };
    let history = useHistory();
    let { mostrarError, userInfo } = React.useContext(AppContext);
    let api = useApi();
    let [cargando, updateCargando] = React.useState(EstadoCargando.CargaInicial);
    let [notFound, updateNotFound] = React.useState(false);
    let [caratula, updateCaratula] = React.useState<any>({});
    let [subregimen, updateSubregimen] = React.useState<any>({});
    let [camposSubregimen, updateCamposSubregimen] = React.useState<any>({});
    let [dolaresFob, updateDolaresFob] = React.useState<any>(null);
    let [dolaresFlete, updateDolaresFlete] = React.useState<any>(null);
    let [dolaresSeguro, updateDolaresSeguro] = React.useState<any>(null);
    let [mensajeErrorAlert, updateMensajeErrorAlert] = React.useState('');
    let [mensajeDialogo, updateMensajeDialogo] = React.useState('');
    let [enviarComisionesExteriorSiEsCero, updateEnviarComisionesExteriorSiEsCero] = React.useState(false);
    let huboCambios = React.useRef({ valor: false });
    const estadoModalReducer = (estado: any, accion: any) => {
        if (accion.tipo === 'mostrar') {
            return { abierto: true, valorSeleccionado: null };
        } else if (accion.tipo === 'cerrar') {
            return { abierto: false, valorSeleccionado: accion.valor };
        }
        return estado;
    };
    const valorInicialEstadoModal = { abierto: false, valorSeleccionado: null };
    let [estadoModalImportadores, updateEstadoModalImportadores] = React.useReducer(estadoModalReducer, valorInicialEstadoModal);
    let [optionsImportadores, updateOptionsImportadores] = React.useState<SelectOption[]>([]);
    let [estadoModalVendedores, updateEstadoModalVendedores] = React.useReducer(estadoModalReducer, valorInicialEstadoModal);
    let [optionsVendedores, updateOptionsVendedores] = React.useState<SelectOption[]>([]);
    let [estadoModalDespachantes, updateEstadoModalDespachantes] = React.useReducer(estadoModalReducer, valorInicialEstadoModal);
    let [optionsDespachantes, updateOptionsDespachantes] = React.useState<SelectOption[]>([]);
    let [tabActual, updateTabActual] = React.useState<string | null>(null);
    let [optionsDomicilioEstampillado, updateOptionsDomicilioEstampillado] = React.useState<SelectOption[]>([]);
    let schema = React.useMemo(() => {
        return Yup.object({
            'CodigoSubregimen': Yup.string().nullable().required('Debe ingresar el subregimen'),
            'CodigoAduana': Yup.string().nullable().required('Debe ingresar la aduana'),
            'CuitDespachante': Yup.string().nullable(),
            'CuitImportador': Yup.string().nullable(),
            'CodigoVendedor': Yup.string().nullable(),
            'TotalFob': Yup.number().nullable().required('Debe ingresar el monto FOB')
                .typeError('El monto fob debe ser un número')
                .moreThan(0, 'El monto fob debe ser mayor a cero'),
            'Flete': Yup.number().nullable().typeError('El flete debe ser un número')
                .test('requerir-flete', 'Debe ingresar el flete', function (valor) {
                    let codigoSubregimen = this.resolve(Yup.ref('CodigoSubregimen'));
                    if (!fleteEsOpcional(codigoSubregimen) && camposSubregimen?.txtMontoFlete !== true) {
                        return valor !== null && valor !== undefined;
                    }
                    return true;
                }).test('mayor-a-cero', 'El flete debe ser mayor a cero', function (valor) {
                    let codigoSubregimen = this.resolve(Yup.ref('CodigoSubregimen'));
                    if (!fleteEsOpcional(codigoSubregimen) && camposSubregimen?.txtMontoFlete !== true) {
                        return valor === null || valor === undefined ? true : (valor > 0);
                    }
                    return valor === null || valor === undefined ? true : (valor >= 0);
                }),
            'Seguro': Yup.number().nullable().typeError('El seguro debe ser un número')
                .test('requerir-seguro', 'Debe ingresar el seguro', function (valor) {
                    let codigoSubregimen = this.resolve(Yup.ref('CodigoSubregimen'));
                    if (!seguroEsOpcional(codigoSubregimen) && camposSubregimen?.txtMontoSeguro !== true) {
                        return valor !== null && valor !== undefined;
                    }
                    return true;
                }).test('mayor-a-cero', 'El seguro debe ser mayor a cero', function (valor) {
                    let codigoSubregimen = this.resolve(Yup.ref('CodigoSubregimen'));
                    if (!seguroEsOpcional(codigoSubregimen) && camposSubregimen?.txtMontoSeguro !== true) {
                        return valor === null || valor === undefined ? true : (valor > 0);
                    }
                    return valor === null || valor === undefined ? true : (valor >= 0);
                }),
            'CodigoDivisaFob': Yup.string().nullable().required('Debe ingresar la divisa del FOB'),
            'CodigoDivisaFlete': Yup.string().nullable().test('requerir-divisa-flete', 'Debe ingresar la divisa del flete', function (valor) {
                let codigoSubregimen = this.resolve(Yup.ref('CodigoSubregimen'));
                if (!fleteEsOpcional(codigoSubregimen) && camposSubregimen?.txtMontoFlete !== true) {
                    return !isNullOrWhiteSpace(valor);
                }
                return true;
            }),
            'CodigoDivisaSeguro': Yup.string().nullable().test('requerir-divisa-seguro', 'Debe ingresar la divisa del seguro', function (valor) {
                let codigoSubregimen = this.resolve(Yup.ref('CodigoSubregimen'));
                if (!seguroEsOpcional(codigoSubregimen) && camposSubregimen?.txtMontoSeguro !== true) {
                    return !isNullOrWhiteSpace(valor);
                }
                return true;
            }),
            'TipoCambioFob': Yup.number().nullable().typeError('El pase fob debe ser un número'),
            'TipoCambioFlete': Yup.number().nullable().typeError('El pase flete debe ser un número'),
            'TipoCambioSeguro': Yup.number().nullable().typeError('El pase seguro debe ser un número'),
            'TipoCambioDolar': Yup.number().nullable().typeError('El tipo cambio dolar debe ser un número'),
            'GastosAnterioresFob': Yup.number().nullable().typeError('Gastos anteriores fob debe ser un número'),
            'GastosPosterioresFob': Yup.number().nullable().typeError('Gastos posteriores fob debe ser un número'),
            'AjusteFob': Yup.number().nullable().typeError('El ajuste fob debe ser un número'),
            'AjusteIncluir': Yup.number().nullable().typeError('El ajuste incluir debe ser un número'),
            'AjusteDeducir': Yup.number().nullable().typeError('El ajuste deducir debe ser un número'),
            'ForzarDescripcionMercaderia': Yup.boolean().nullable(),
            'DescripcionMercaderia': Yup.string().nullable(),
            'TerceroCuit': Yup.string().nullable(),
            'ForzarEstadoMercaderia': Yup.boolean().nullable(),
            'CodigoEstadoMercaderia': Yup.string().nullable(),
            'ForzarPaisOrigen': Yup.boolean().nullable(),
            'CodigoPaisOrigen': Yup.string().nullable(),
            'ForzarPaisDestino': Yup.boolean().nullable(),
            'CodigoPaisDestinoAForzar': Yup.string().nullable(),
            'CodigoPaisDestino': Yup.string().nullable(),
            'FechaArriboAprox': Yup.date().nullable(),
            'FechaEmbarqueAprox': Yup.date().nullable(),
            'FechaGiro': Yup.date().nullable(),
            'SIMIPlazoGiro': Yup.number().nullable(),
            'MontoGiradoDOL': Yup.number().nullable(),
            'CodigoAduanaSalida': Yup.string().nullable(),
            'IdentificadorExterno': Yup.string().nullable(),
            'CodigoPaisFacturacion': Yup.string().nullable(),
            'ForzarStandard': Yup.string().nullable().oneOf(['n', 'N', 's', 'S'], context => `Debe ser uno de los siguientes valores: ${context.values}`),
            'ForzarRegalias': Yup.string().nullable().oneOf(['n', 'N', 's', 'S'], context => `Debe ser uno de los siguientes valores: ${context.values}`),
            'AutorizDestSuspensiva': Yup.string().nullable(),
            'MotivoDestSuspensiva': Yup.string().nullable(),
            'FechaCierreVenta': Yup.date().nullable(),
            'PorcentajeSeguro': Yup.number().nullable().typeError('El porcentaje seguro debe ser un número'),
            'ComisionesExterior': Yup.number().nullable().typeError('Comisiones al exterior debe ser un número'),
            'DCBancos': Yup.string().nullable(),
            'Anexo': Yup.string().nullable(),
            'FacilidadPago': Yup.string().nullable(),
            'OpcionesIVA': Yup.string().nullable(),
            'OpcionesGanancias': Yup.string().nullable(),
            'PlazoAutorizacion': Yup.number().nullable().typeError('El plazo de autorización debe ser un número')
                .min(0, 'Debe ser mayor o igual a cero').integer('Debe ser un número entero'),
            'FechaEmbarque': Yup.date().nullable(),
            'CondicionVenta': Yup.string().nullable(),
            'DatoComprador': Yup.string().nullable(),
            'DepositoAlmacenamiento': Yup.string().nullable().test('requerir-dep-alm', 'Debe ingresar el deposito de almacenamiento', function (valor) {
                if (camposSubregimen?.txtDepAlmacenamiento !== true) {
                    return !isNullOrWhiteSpace(valor);
                }
                return true;
            }),
            'DCKgNeto': Yup.number().nullable().typeError('El peso neto debe ser un número'),
            'FechaEmisionFactura': Yup.date().nullable(),
            'DomicilioEstampillado': Yup.string().nullable(),
            'NroTransInter': Yup.string().nullable(),
            'CuitTransporteTRM': Yup.string().nullable().test('requerir-cuit-transportista', 'Debe ingresar el cuit del transportista', function (valor) {
                if (camposSubregimen?.txtCuitTransportistaTRM !== true) {
                    return !isNullOrWhiteSpace(valor);
                }
                return true;
            }),
            'VentaAlExterior': Yup.string().nullable().test('requerir-venta-al-exterior', 'Debe ingresar la venta al exterior', function (valor) {
                if (camposSubregimen?.txtVentaAlExterior !== true) {
                    return !isNullOrWhiteSpace(valor);
                }
                return true;
            }),
            'PresenciaFacturas': Yup.string().nullable()
        });
    }, [camposSubregimen]);
    let [estadoFacturas, updateEstadoFacturas] = React.useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'inicializar') {
            return {
                ...estado, facturas: accion.facturas.map((item: string, indice: number) => ({
                    Indice: indice, Valor: item
                })), presencia: accion.presencia ?? ''
            };
        } else if (accion.tipo === 'insertFactura') {
            let nuevasFacturas = estado.facturas.map((factura: any) => factura.Valor);
            if (estado.indiceModificando !== null && estado.indiceModificando !== undefined) {
                nuevasFacturas.splice(estado.indiceModificando, 1);
            }
            nuevasFacturas.push(accion.valor);
            return {
                ...estado, facturas: nuevasFacturas.map((item: string, indice: number) => ({
                    Indice: indice, Valor: item
                })), creando: false, modificando: false, indiceModificando: null
            };
        } else if (accion.tipo === 'deleteFactura') {
            let nuevasFacturas = estado.facturas.map((factura: any) => factura.Valor);
            nuevasFacturas.splice(accion.valor, 1);
            return {
                ...estado, facturas: nuevasFacturas.map((item: string, indice: number) => ({
                    Indice: indice, Valor: item
                })), creando: false, modificando: false, indiceModificando: null
            };
        }
        else if (accion.tipo === 'ventanaCrear') {
            return { ...estado, creando: true, modificando: false, indiceModificando: null };
        } else if (accion.tipo === 'ventanaModificar') {
            return { ...estado, creando: false, modificando: true, indiceModificando: accion.valor };
        } else if (accion.tipo === 'esconderVentana') {
            return { ...estado, creando: false, modificando: false, indiceModificando: null };
        } else if (accion.tipo === 'setPresencia') {
            return { ...estado, presencia: accion.valor ?? '' };
        }
        return estado;
    }, { facturas: [], creando: false, modificando: false, indiceModificando: null, presencia: '' });
    let [estadoInfComps, updateEstadoInfComps] = React.useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'inicializar') {
            return { ...estado, infComps: accion.infComps };
        } else if (accion.tipo === 'insertInfComp') {
            let nuevasInfComps = Array.from(estado.infComps);
            if (estado.valorModificando) {
                let indice = nuevasInfComps.findIndex((item: any) => item.Codigo === estado.valorModificando.Codigo);
                nuevasInfComps.splice(indice, 1);
            }
            nuevasInfComps.push(accion.valor);
            return { ...estado, infComps: nuevasInfComps, creando: false, modificando: false, valorModificando: null };
        } else if (accion.tipo === 'deleteInfComp') {
            let nuevasInfComps = Array.from(estado.infComps);
            let indice = nuevasInfComps.findIndex((item: any) => item.Codigo === accion.valor);
            if (indice > -1) {
                nuevasInfComps.splice(indice, 1);
            }
            return { ...estado, infComps: nuevasInfComps, creando: false, modificando: false, valorModificando: null };
        }
        else if (accion.tipo === 'ventanaCrear') {
            return { ...estado, creando: true, modificando: false, valorModificando: null };
        } else if (accion.tipo === 'ventanaModificar') {
            return { ...estado, creando: false, modificando: true, valorModificando: accion.valor };
        } else if (accion.tipo === 'esconderVentana') {
            return { ...estado, creando: false, modificando: false, valorModificando: null };
        }
        return estado;
    }, { infComps: [], creando: false, modificando: false, valorModificando: null });
    let [estadoCodigosProrrateo, updateEstadoCodigosProrrateo] = React.useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'inicializar') {
            return { ...estado, codigos: accion.codigos };
        } else if (accion.tipo === 'insertCodigo') {
            let nuevosCodigos = Array.from(estado.codigos);
            if (estado.valorModificando) {
                let indice = nuevosCodigos.findIndex((item: any) => item.Codigo === estado.valorModificando.Codigo);
                nuevosCodigos.splice(indice, 1);
            }
            nuevosCodigos.push(accion.valor);
            return { ...estado, codigos: nuevosCodigos, creando: false, modificando: false, valorModificando: null };
        } else if (accion.tipo === 'deleteCodigo') {
            let nuevosCodigos = Array.from(estado.codigos);
            let indice = nuevosCodigos.findIndex((item: any) => item.Codigo === accion.valor);
            if (indice > -1) {
                nuevosCodigos.splice(indice, 1);
            }
            return { ...estado, codigos: nuevosCodigos, creando: false, modificando: false, valorModificando: null };
        }
        else if (accion.tipo === 'ventanaCrear') {
            return { ...estado, creando: true, modificando: false, valorModificando: null };
        } else if (accion.tipo === 'ventanaModificar') {
            return { ...estado, creando: false, modificando: true, valorModificando: accion.valor };
        } else if (accion.tipo === 'esconderVentana') {
            return { ...estado, creando: false, modificando: false, valorModificando: null };
        }
        return estado;
    }, { codigos: [], creando: false, modificando: false, valorModificando: null });
    async function cargarDespachantes(valorSeleccionado?: string) {
        let respuesta = await api.getDespachantes();
        let array = respuesta.map((item: any) => ({ value: item.CUIT, label: item.Nombre }));
        updateOptionsDespachantes(array);
        if (!isNullOrWhiteSpace(valorSeleccionado)) {
            formikRef.current?.setFieldValue('CuitDespachante', valorSeleccionado);
        }
    }
    async function cargarImportadores(valorSeleccionado?: string) {
        let respuesta = await api.getImportadores();
        let array = respuesta.map((item: any) => ({ value: item.CUIT, label: item.Nombre }));
        updateOptionsImportadores(array);
        if (!isNullOrWhiteSpace(valorSeleccionado)) {
            formikRef.current?.setFieldValue('CuitImportador', valorSeleccionado);
            await onCuitImportadorChange(valorSeleccionado);
        }
    }
    async function cargarVendedores(valorSeleccionado?: string) {
        let respuesta = await api.getVendedores();
        let array = respuesta.map((item: any) => ({ value: item.Codigo, label: item.Nombre }));
        updateOptionsVendedores(array);
        if (!isNullOrWhiteSpace(valorSeleccionado)) {
            formikRef.current?.setFieldValue('CodigoVendedor', valorSeleccionado);
        }
    }
    async function getDomiciliosEstampillado(cuitImportador: string) {
        try {
            let importador = await api.getImportador(cuitImportador);
            return importador.DomiciliosEstampillado ?? [];
        } catch (error) {
            if (api.isCancel(error)) {
                throw error;
            }
            console.error(`Error al obtener domicilios estampillado de importador ${cuitImportador}`, error);
            return [];
        }
    }
    React.useEffect(() => {
        async function cargar() {
            try {

                updateCargando(EstadoCargando.CargaInicial);

                let caratula = await api.getCaratula(interno);
                if (!caratula) {
                    updateNotFound(true);
                    updateCargando(EstadoCargando.Listo);
                    return;
                }
                updateCaratula(caratula);
                await cargarImportadores();
                await cargarVendedores();
                await cargarDespachantes();
                let stringStandard = '';
                let stringRegalias = '';
                if (caratula.ForzarStandard === true) {
                    stringStandard = 'S';
                } else {
                    stringStandard = 'N';
                }
                if (caratula.ForzarRegalias === true) {
                    stringRegalias = 'S';
                } else {
                    stringRegalias = 'N';
                }
                let paseFob = '0', dolFob = '0', paseFlete = '0', dolFlete = '0', paseSeguro = '0', dolSeguro = '0';
                if (!isNullOrWhiteSpace(caratula.CodigoDivisaFob)) {
                    if (caratula.TipoCambioFob > 0) {
                        paseFob = toFixedDecimal(caratula.TipoCambioFob, 5);
                        dolFob = toFixedDecimal(new Decimal(caratula.TipoCambioFob).mul(caratula.TotalFob), 2);
                    } else {
                        let respuestaTipoCambio = await api.getTipoCambioGuardado(caratula.CodigoDivisaFob);
                        if (respuestaTipoCambio) {
                            paseFob = toFixedDecimal(respuestaTipoCambio.Valor, 2);
                            dolFob = toFixedDecimal(new Decimal(respuestaTipoCambio.Valor).mul(caratula.TotalFob), 2);
                        }
                    }
                }
                if (!isNullOrWhiteSpace(caratula.CodigoDivisaFlete)) {
                    if (caratula.TipoCambioFlete > 0) {
                        paseFlete = toFixedDecimal(caratula.TipoCambioFlete, 5);
                        dolFlete = toFixedDecimal(new Decimal(caratula.TipoCambioFlete).mul(caratula.Flete), 2);
                    } else {
                        let respuestaTipoCambio = await api.getTipoCambioGuardado(caratula.TipoCambioFlete);
                        if (respuestaTipoCambio) {
                            paseFlete = toFixedDecimal(respuestaTipoCambio.Valor, 2);
                            dolFlete = toFixedDecimal(new Decimal(respuestaTipoCambio.Valor).mul(caratula.Flete), 2);
                        }
                    }
                }
                if (!isNullOrWhiteSpace(caratula.CodigoDivisaSeguro)) {
                    if (caratula.TipoCambioSeguro > 0) {
                        paseSeguro = toFixedDecimal(caratula.TipoCambioSeguro, 5);
                        dolSeguro = toFixedDecimal(new Decimal(caratula.TipoCambioSeguro).mul(caratula.Seguro), 2);
                    } else {
                        let respuestaTipoCambio = await api.getTipoCambioGuardado(caratula.CodigoDivisaSeguro);
                        if (respuestaTipoCambio) {
                            paseSeguro = toFixedDecimal(respuestaTipoCambio.Valor, 2);
                            dolSeguro = toFixedDecimal(new Decimal(respuestaTipoCambio.Valor).mul(caratula.Seguro), 2);
                        }
                    }
                }
                if (!isNullOrWhiteSpace(caratula.CuitImportador)) {
                    let domiciliosEstampillado = await getDomiciliosEstampillado(caratula.CuitImportador);
                    if (!isNullOrWhiteSpace(caratula.DomicilioEstampillado) && !domiciliosEstampillado.includes(caratula.DomicilioEstampillado)) {
                        domiciliosEstampillado.push(caratula.DomicilioEstampillado);
                    }
                    updateOptionsDomicilioEstampillado(domiciliosEstampillado.map((item: string) => ({ value: item, label: item })));
                }
                let presenciaFacturas = null;
                if (caratula.PresenciaFacturas === true) {
                    presenciaFacturas = 'S';
                } else if (caratula.PresenciaFacturas === false) {
                    presenciaFacturas = 'N';
                }
                formikRef.current?.resetForm({
                    values: {
                        CodigoSubregimen: caratula.CodigoSubregimen,
                        CodigoAduana: caratula.CodigoAduana,
                        CuitDespachante: caratula.CuitDespachante,
                        CuitImportador: caratula.CuitImportador,
                        CodigoVendedor: caratula.CodigoVendedor,
                        TotalFob: toFixedDecimal(caratula.TotalFob, 2),
                        Flete: toFixedDecimal(caratula.Flete, 2),
                        Seguro: toFixedDecimal(caratula.Seguro, 2),
                        CodigoDivisaFob: caratula.CodigoDivisaFob,
                        CodigoDivisaFlete: caratula.CodigoDivisaFlete,
                        CodigoDivisaSeguro: caratula.CodigoDivisaSeguro,
                        TipoCambioDolar: toFixedDecimal(caratula.TipoCambioDolar, 5),
                        TipoCambioFob: paseFob,
                        TipoCambioFlete: paseFlete,
                        TipoCambioSeguro: paseSeguro,
                        GastosAnterioresFob: toFixedDecimal(caratula.GastosAnterioresFob),
                        GastosPosterioresFob: toFixedDecimal(caratula.GastosPosterioresFob),
                        AjusteFob: toFixedDecimal(caratula.AjusteFob, 2),
                        AjusteIncluir: toFixedDecimal(caratula.AjusteIncluir, 2),
                        AjusteDeducir: toFixedDecimal(caratula.AjusteDeducir, 2),
                        ForzarDescripcionMercaderia: caratula.ForzarDescripcionMercaderia,
                        DescripcionMercaderia: caratula.DescripcionMercaderia,
                        TerceroCuit: caratula.TerceroCuit,
                        ForzarEstadoMercaderia: caratula.ForzarEstadoMercaderia,
                        CodigoEstadoMercaderia: caratula.CodigoEstadoMercaderia,
                        ForzarPaisOrigen: caratula.ForzarPaisOrigen,
                        CodigoPaisOrigen: caratula.CodigoPaisOrigen,
                        ForzarPaisDestino: caratula.ForzarPaisDestino,
                        CodigoPaisDestinoAForzar: caratula.CodigoPaisDestinoAForzar,
                        CodigoPaisDestino: caratula.CodigoPaisDestino,
                        FechaArriboAprox: toHtmlDate(caratula.FechaArriboAprox),
                        FechaEmbarqueAprox: toHtmlDate(caratula.FechaEmbarqueAprox),
                        FechaGiro: toHtmlDate(caratula.FechaGiro),
                        SIMIPlazoGiro: caratula.SIMIPlazoGiro,
                        MontoGiradoDOL: caratula.MontoGiradoDOL,
                        CodigoAduanaSalida: caratula.CodigoAduanaSalida,
                        IdentificadorExterno: caratula.IdentificadorExterno,
                        CodigoPaisFacturacion: caratula.CodigoPaisFacturacion,
                        ForzarStandard: stringStandard,
                        ForzarRegalias: stringRegalias,
                        AutorizDestSuspensiva: caratula.AutorizDestSuspensiva,
                        MotivoDestSuspensiva: caratula.MotivoDestSuspensiva,
                        FechaCierreVenta: toHtmlDate(caratula.FechaCierreVenta),
                        PorcentajeSeguro: toFixedDecimal(caratula.PorcentajeSeguro, 2),
                        ComisionesExterior: toFixedDecimal(caratula.ComisionesExterior, 2),
                        DCBancos: caratula.DCBancos,
                        Anexo: caratula.Anexo,
                        FacilidadPago: caratula.FacilidadPago,
                        OpcionesIVA: caratula.OpcionesIVA,
                        OpcionesGanancias: caratula.OpcionesGanancias,
                        PlazoAutorizacion: caratula.PlazoAutorizacion,
                        FechaEmbarque: toHtmlDate(caratula.FechaEmbarque),
                        CondicionVenta: caratula.CondicionVenta,
                        DatoComprador: caratula.DatoComprador,
                        DepositoAlmacenamiento: caratula.DepositoAlmacenamiento,
                        DCKgNeto: toFixedDecimal(caratula.DCKgNeto, 2),
                        FechaEmisionFactura: toHtmlDate(caratula.FechaEmisionFactura),
                        DomicilioEstampillado: caratula.DomicilioEstampillado,
                        NroTransInter: caratula.NroTransInter,
                        CuitTransporteTRM: caratula.CuitTransporteTRM,
                        VentaAlExterior: caratula.VentaAlExterior,
                        PresenciaFacturas: presenciaFacturas
                    }
                });
                onSubregimenChange(caratula.CodigoSubregimen);
                updateDolaresFob(dolFob);
                updateDolaresFlete(dolFlete);
                updateDolaresSeguro(dolSeguro);
                updateEstadoFacturas({
                    tipo: 'inicializar', facturas: caratula.Facturas ?? [],
                    presencia: presenciaFacturas
                });
                updateEstadoInfComps({ tipo: 'inicializar', infComps: caratula.InformacionComplementaria ?? [] });
                updateEstadoCodigosProrrateo({ tipo: 'inicializar', codigos: caratula.CodigosProrrateo ?? [] });
                let configuracionEmpresa = await api.getConfiguracionEmpresa();
                updateEnviarComisionesExteriorSiEsCero(configuracionEmpresa.Configuracion?.EnviarComisionesAExteriorEnCero ?? false);
                updateCargando(EstadoCargando.Listo);
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al cargar caratula');
                    mostrarError('Error al cargar caratula');
                }
                updateCargando(EstadoCargando.Listo);
                return;
            }
        }
        cargar();
        //eslint-disable-next-line
    }, []);
    React.useEffect(() => {
        async function cargar() {
            try {
                updateCargando(EstadoCargando.Cargando);
                await cargarImportadores(estadoModalImportadores.valorSeleccionado);
                updateCargando(EstadoCargando.Listo);
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al cargar clientes', error);
                    mostrarError('Error al cargar clientes');
                    updateCargando(EstadoCargando.Listo);
                }
            }
        }
        if (!estadoModalImportadores.abierto && cargando !== EstadoCargando.CargaInicial) {
            cargar();
        }
        //eslint-disable-next-line
    }, [estadoModalImportadores.abierto]);
    React.useEffect(() => {
        async function cargar() {
            try {
                updateCargando(EstadoCargando.Cargando);
                await cargarVendedores(estadoModalVendedores.valorSeleccionado);
                updateCargando(EstadoCargando.Listo);
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al cargar vendedores', error);
                    mostrarError('Error al cargar vendedores');
                    updateCargando(EstadoCargando.Listo);
                }
            }
        }
        if (!estadoModalVendedores.abierto && cargando !== EstadoCargando.CargaInicial) {
            cargar();
        }
        //eslint-disable-next-line
    }, [estadoModalVendedores.abierto]);
    React.useEffect(() => {
        async function cargar() {
            try {
                updateCargando(EstadoCargando.Cargando);
                await cargarDespachantes(estadoModalDespachantes.valorSeleccionado);
                updateCargando(EstadoCargando.Listo);
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al cargar despachantes', error);
                    mostrarError('Error al cargar despachantes');
                    updateCargando(EstadoCargando.Listo);
                }
            }
        }
        if (!estadoModalDespachantes.abierto && cargando !== EstadoCargando.CargaInicial) {
            cargar();
        }
        //eslint-disable-next-line
    }, [estadoModalDespachantes.abierto]);
    function calcularMontoSeguroSegunPorcentaje() {
        try {
            let porcentaje = new Decimal(formikRef.current?.values.PorcentajeSeguro);
            let montoFob = new Decimal(formikRef.current?.values.TotalFob);
            let montoFlete = new Decimal(formikRef.current?.values.Flete);
            let tipoCambioFob = new Decimal(formikRef.current?.values.TipoCambioFob);
            let tipoCambioFlete = new Decimal(formikRef.current?.values.TipoCambioFlete);
            let tipoCambioSeguro = new Decimal(formikRef.current?.values.TipoCambioSeguro);
            if (porcentaje.comparedTo(0) === 1) {
                if (tipoCambioFob.comparedTo(0) === 0) {
                    tipoCambioFob = new Decimal(1);
                }
                if (tipoCambioFlete.comparedTo(0) === 0) {
                    tipoCambioFlete = new Decimal(1);
                }
                let montoBase = montoFob.mul(tipoCambioFob).plus(montoFlete.mul(tipoCambioFlete));
                let montoSeguro = porcentaje.div(new Decimal(100)).mul(montoBase).div(tipoCambioSeguro);
                formikRef.current?.setFieldValue('Seguro', toFixedDecimal(montoSeguro, 2));
            }
        } catch (error) {
        }
    }
    async function submit(values: any, actions: FormikHelpers<any>) {
        try {
            let tieneLock = await api.obtenerLock(TipoLock.Caratula, caratula.Carpeta);
            if (!tieneLock) {
                updateMensajeErrorAlert('No se puede modificar la caratula porque otro usuario la está modificando');
                return false;
            }

            //me fijo si fecha cierre de venta esta habilitado para este subreg
            //sino esta lo vacio, porque bloquea el textbox, pero no vacia
            //si es true: no esta habilitado.... logica de ale
            if (camposSubregimen?.dateFechaCierreVenta){
                values.FechaCierreVenta = '';
            }

            await api.setEnviarComisionesAExteriorEnCero(enviarComisionesExteriorSiEsCero);
            let standard = null;
            if (values.ForzarStandard === 'N' || values.ForzarStandard === 'n') {
                standard = false;
            }
            if (values.ForzarStandard === 'S' || values.ForzarStandard === 's') {
                standard = true;
            }
            let regalias = null;
            if (values.ForzarRegalias === 'N' || values.ForzarRegalias === 'n') {
                regalias = false;
            }
            if (values.ForzarRegalias === 'S' || values.ForzarRegalias === 's') {
                regalias = true;
            }
            let flete = values.Flete || 0;
            let seguro = values.Seguro || 0;
            let gastosAnterioresFob = isNullOrWhiteSpace(values.GastosAnterioresFob) ? null : toFixedDecimal(values.GastosAnterioresFob, 5);
            let gastosPosterioresFob = isNullOrWhiteSpace(values.GastosPosterioresFob) ? null : toFixedDecimal(values.GastosPosterioresFob, 5);
            let presenciaFacturas = null;
            if (values.PresenciaFacturas === 'S') {
                presenciaFacturas = true;
            } else if (values.PresenciaFacturas === 'N') {
                presenciaFacturas = false;
            }
            let modelo = {
                ...values,
                EmpresaId: userInfo.empresaActual,
                Carpeta: interno,
                Flete: flete,
                Seguro: seguro,
                GastosAnterioresFob: gastosAnterioresFob,
                GastosPosterioresFob: gastosPosterioresFob,
                ForzarStandard: standard,
                ForzarRegalias: regalias,
                PresenciaFacturas: presenciaFacturas,
                Facturas: estadoFacturas.facturas.map((item: any) => item.Valor),
                CodigosProrrateo: estadoCodigosProrrateo.codigos,
                AjusteFob: estadoCodigosProrrateo.codigos.length > 0 ? null : values.AjusteFob,
                InformacionComplementaria: estadoInfComps.infComps,
            }
            let { exito, error } = await api.updateCaratula(modelo);
            if (exito) {
                await api.eliminarLock(TipoLock.Caratula, interno);
                return true;
            } else {
                updateMensajeErrorAlert(error);
                return false;
            }
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al guardar caratula', error);
                mostrarError('Error al guardar caratula');
            }
            return false;
        }
    }
    React.useEffect(() => {
        if (estadoCodigosProrrateo.codigos.length > 0) {
            formikRef.current?.setFieldValue('AjusteFob', '');
        }
    }, [estadoCodigosProrrateo.codigos]);
    async function onSubregimenChange(subregimen: OnValueChangeArgument) {
        if (subregimen && !Array.isArray(subregimen)) {
            try {
                if (cargando === EstadoCargando.Listo) {
                    eventoHuboCambios();
                }
                let subregimenes = await api.getSubregimenes();
                let item: any = subregimenes.find((s: any) => s.Codigo === subregimen);
                if (item) {
                    updateSubregimen(item);
                }
                let camposSubregimen = await api.getCamposSubregimen();
                item = camposSubregimen.find((c: any) => c.Subregimen === subregimen);
                if (item) {
                    updateCamposSubregimen(item.Campos);
                }
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al obtener datos subregimen', error);
                }
            }
        }
    }
    function onTipoCambioFobBlur() {
        try {
            let pase = new Decimal(formikRef.current!.values.TipoCambioFob);
            if (pase.comparedTo(0) === 0) {
                pase = new Decimal(1);
            }
            let monedaFob = formikRef.current!.values.CodigoDivisaFob;
            let monedaFlete = formikRef.current!.values.CodigoDivisaFlete;
            let monedaSeguro = formikRef.current!.values.CodigoDivisaSeguro;
            if (!isNullOrWhiteSpace(monedaFob) && !isNullOrWhiteSpace(monedaFlete) && monedaFob === monedaFlete) {
                eventoHuboCambios();
                formikRef.current?.setFieldValue('TipoCambioFlete', toFixedDecimal(pase, 5));
                try {
                    let montoFlete = new Decimal(formikRef.current!.values.Flete);
                    updateDolaresFlete(toFixedDecimal(montoFlete.mul(pase), 2));
                } catch (error) {
                }
            }
            if (!isNullOrWhiteSpace(monedaFob) && !isNullOrWhiteSpace(monedaSeguro) && monedaFob === monedaSeguro) {
                eventoHuboCambios();
                formikRef.current?.setFieldValue('TipoCambioSeguro', toFixedDecimal(pase, 5));
                try {
                    let montoSeguro = new Decimal(formikRef.current!.values.Seguro);
                    updateDolaresSeguro(toFixedDecimal(montoSeguro.mul(pase), 2));
                } catch (error) {
                }
            }
            try {
                let montoFob = new Decimal(formikRef.current!.values.TotalFob);
                updateDolaresFob(toFixedDecimal(montoFob.mul(pase), 2));
            } catch (error) {
            }
            calcularMontoSeguroSegunPorcentaje();
            api.guardarTipoCambio(monedaFob, toFixedDecimal(pase, 5)).catch(error => {
                if (!api.isCancel(error)) {
                    console.error('Error al guardar tipo cambio', error);
                }
            });
        } catch (error) {

        }
    }
    function onTipoCambioFleteBlur() {
        try {
            let pase = new Decimal(formikRef.current!.values.TipoCambioFlete);
            if (pase.comparedTo(0) === 0) {
                pase = new Decimal(1);
            }
            let monedaFob = formikRef.current!.values.CodigoDivisaFob;
            let monedaFlete = formikRef.current!.values.CodigoDivisaFlete;
            let monedaSeguro = formikRef.current!.values.CodigoDivisaSeguro;
            if (!isNullOrWhiteSpace(monedaFob) && !isNullOrWhiteSpace(monedaFlete) && monedaFob === monedaFlete) {
                eventoHuboCambios();
                formikRef.current?.setFieldValue('TipoCambioFob', toFixedDecimal(pase, 5));
                try {
                    let montoFob = new Decimal(formikRef.current!.values.TotalFob);
                    updateDolaresFob(toFixedDecimal(montoFob.mul(pase), 2));
                } catch (error) {
                }
            }
            if (!isNullOrWhiteSpace(monedaFlete) && !isNullOrWhiteSpace(monedaSeguro) && monedaFlete === monedaSeguro) {
                eventoHuboCambios();
                formikRef.current?.setFieldValue('TipoCambioSeguro', toFixedDecimal(pase, 5));
                try {
                    let montoSeguro = new Decimal(formikRef.current!.values.Seguro);
                    updateDolaresSeguro(toFixedDecimal(montoSeguro.mul(pase), 2));
                } catch (error) {
                }
            }
            try {
                let montoFlete = new Decimal(formikRef.current!.values.Flete);
                updateDolaresFlete(toFixedDecimal(montoFlete.mul(pase), 2));
            } catch (error) {
            }
            calcularMontoSeguroSegunPorcentaje();
            api.guardarTipoCambio(monedaFlete, toFixedDecimal(pase, 5)).catch(error => {
                if (!api.isCancel(error)) {
                    console.error('Error al guardar tipo cambio', error);
                }
            });
        } catch (error) {

        }
    }
    function onTipoCambioSeguroBlur() {
        try {
            let pase = new Decimal(formikRef.current!.values.TipoCambioSeguro);
            if (pase.comparedTo(0) === 0) {
                pase = new Decimal(1);
            }
            let monedaFob = formikRef.current!.values.CodigoDivisaFob;
            let monedaFlete = formikRef.current!.values.CodigoDivisaFlete;
            let monedaSeguro = formikRef.current!.values.CodigoDivisaSeguro;
            if (!isNullOrWhiteSpace(monedaFob) && !isNullOrWhiteSpace(monedaSeguro) && monedaFob === monedaSeguro) {
                eventoHuboCambios();
                formikRef.current?.setFieldValue('TipoCambioFob', toFixedDecimal(pase, 5));
                try {
                    let montoFob = new Decimal(formikRef.current!.values.TotalFob);
                    updateDolaresFob(toFixedDecimal(montoFob.mul(pase), 2));
                } catch (error) {
                }
            }
            if (!isNullOrWhiteSpace(monedaFlete) && !isNullOrWhiteSpace(monedaSeguro) && monedaFlete === monedaSeguro) {
                eventoHuboCambios();
                formikRef.current?.setFieldValue('TipoCambioFlete', toFixedDecimal(pase, 5));
                try {
                    let montoFlete = new Decimal(formikRef.current!.values.Flete);
                    updateDolaresFlete(toFixedDecimal(montoFlete.mul(pase), 2));
                } catch (error) {
                }
            }
            try {
                let montoSeguro = new Decimal(formikRef.current!.values.Seguro);
                updateDolaresSeguro(toFixedDecimal(montoSeguro.mul(pase), 2));
            } catch (error) {
            }
            calcularMontoSeguroSegunPorcentaje();
            api.guardarTipoCambio(monedaSeguro, toFixedDecimal(pase, 5)).catch(error => {
                if (!api.isCancel(error)) {
                    console.error('Error al guardar tipo cambio', error);
                }
            });
        } catch (error) {

        }
    }
    function onMonedaChange(campo: string) {
        return async (valor: OnValueChangeArgument) => {
            try {
                eventoHuboCambios();
                let moneda = valor as string | null | undefined;
                if (isNullOrWhiteSpace(moneda)) {
                    return;
                }
                let pase = new Decimal(0);
                let cotizaciones = await api.getCotizacionesDivisas() ?? [];
                let cotizacion = cotizaciones.find((item: any) => item.CodigoSIM === moneda);
                if (cotizacion && cotizacion.TipoPaseVenta) {
                    pase = new Decimal(cotizacion.TipoPaseVenta);
                } else {
                    try {
                        let respuestaTipoCambio = await api.getTipoCambioGuardado(moneda as string);
                        if (respuestaTipoCambio) {
                            pase = new Decimal(respuestaTipoCambio.Valor);
                        }
                    } catch (error) {
                        if (!api.isCancel(error)) {
                            console.error('Error al obtener tipo cambio', error);
                        }
                    }
                }
                formikRef.current?.setFieldValue(campo, toFixedDecimal(pase, 5));
                calcularMontoSeguroSegunPorcentaje();
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al obtener cotizaciones divisas', error);
                }
            }
        }
    }
    async function cargarCotizacionesClick() {
        updateCargando(EstadoCargando.Cargando);
        try {
            let cotizaciones = await api.getCotizacionesDivisas() ?? [];
            if (cotizaciones.length === 0) {
                mostrarError('No hay cotizaciones disponibles en AFIP');
            } else {
                let monedaFob = formikRef.current!.values.CodigoDivisaFob;
                if (!isNullOrWhiteSpace(monedaFob)) {
                    let cotizacion = cotizaciones.find((item: any) => item.CodigoSIM === monedaFob);
                    if (cotizacion && cotizacion.Venta) {
                        eventoHuboCambios();
                        formikRef.current!.setFieldValue('TipoCambioFob', toFixedDecimal(cotizacion.TipoPaseVenta, 5));
                        try {
                            let montoFob = new Decimal(formikRef.current!.values.TipoCambioFob);
                            updateDolaresFob(toFixedDecimal(montoFob.mul(cotizacion.TipoPaseVenta), 2));
                        } catch (error) {
                        }
                    }
                }
                let monedaFlete = formikRef.current!.values.CodigoDivisaFlete;
                if (!isNullOrWhiteSpace(monedaFlete)) {
                    let cotizacion = cotizaciones.find((item: any) => item.CodigoSIM === monedaFlete);
                    if (cotizacion && cotizacion.Venta) {
                        eventoHuboCambios();
                        formikRef.current!.setFieldValue('TipoCambioFlete', toFixedDecimal(cotizacion.TipoPaseVenta, 5));
                        try {
                            let montoFlete = new Decimal(formikRef.current!.values.TipoCambioFlete);
                            updateDolaresFlete(toFixedDecimal(montoFlete.mul(cotizacion.TipoPaseVenta), 2));
                        } catch (error) {
                        }
                    }
                }
                let monedaSeguro = formikRef.current!.values.CodigoDivisaSeguro;
                if (!isNullOrWhiteSpace(monedaSeguro)) {
                    let cotizacion = cotizaciones.find((item: any) => item.CodigoSIM === monedaSeguro);
                    if (cotizacion && cotizacion.Venta) {
                        eventoHuboCambios();
                        formikRef.current!.setFieldValue('TipoCambioSeguro', toFixedDecimal(cotizacion.TipoPaseVenta, 5));
                        try {
                            let montoSeguro = new Decimal(formikRef.current!.values.TipoCambioSeguro);
                            updateDolaresSeguro(toFixedDecimal(montoSeguro.mul(cotizacion.TipoPaseVenta), 2));
                        } catch (error) {
                        }
                    }
                }
                let cotizacionDolar = cotizaciones.find((item: any) => item.CodigoSIM === 'DOL');
                if (cotizacionDolar && cotizacionDolar.Venta) {
                    eventoHuboCambios();
                    formikRef.current!.setFieldValue('TipoCambioDolar', toFixedDecimal(cotizacionDolar.Venta, 5));
                }
            }
            updateCargando(EstadoCargando.Listo);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al cargar cotizaciones', error);
                mostrarError('Error al cargar cotizaciones');
                updateCargando(EstadoCargando.Listo);
            }
        }
    }
    async function onCuitImportadorChange(cuitImportador: string | null | undefined) {
        try {
            eventoHuboCambios();
            if (!isNullOrWhiteSpace(cuitImportador)) {
                let domiciliosEstampillado = await getDomiciliosEstampillado(cuitImportador as string);
                updateOptionsDomicilioEstampillado(domiciliosEstampillado.map((item: string) => ({ value: item, label: item })));
            } else {
                updateOptionsDomicilioEstampillado([]);
            }
            formikRef.current?.setFieldValue('DomicilioEstampillado', null);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al cargar domicilios de estampillado', error);
            }
        }
    }
    function preguntarGuardarCambios() {
        if (cargando === EstadoCargando.CargaInicial || caratula?.Bloqueada || !huboCambios.current.valor) {
            return Promise.resolve(true);
        }
        if (formikRef.current!.isValid) {
            updateMensajeDialogo('¿Desea guardar los cambios en la caratula?');
            return dialogoRef.current!.mostrar().then(() => {
                let promise = formikRef.current!.submitForm() as unknown as Promise<boolean>;
                return promise.catch(() => false);
            }).catch(() => true);
        } else {
            updateMensajeDialogo('Hay errores en la caratula. ¿Está seguro que desea salir?');
            return dialogoRef.current!.mostrar().then(() => true).catch(() => false);
        }
    }
    let { disableRedirectEventCallback } = useRedirectEventCallback(preguntarGuardarCambios);
    async function getOptionsInformacionComplementaria() {
        let infComps = await api.getInformacionComplementaria();
        //infComps = infComps.filter((i: any) => !i.Codigo.startsWith('SIMI-DESC-MERC'));

/*        let mainArray = [['apple', 'banana'], ['orange', 'pineapple'], ['kiwi', 'watermelon']];
        let filterArray = ['apple', 'banana'];
        filterInfoComp*/
        infComps = infComps.filter((item: any) => !filterInfoComp.includes(item.Codigo));
        return convertirDatosGenericosAOption(infComps);

    }
    const eventoHuboCambios = () => {
        huboCambios.current.valor = true;
    }
    return notFound ? (<NotFoundComponent></NotFoundComponent>) : (<>
        <h2>Caratula de <Link to={'/caratulas/' + encodeURIComponent(interno)}>{interno}</Link></h2>
        <Formik initialValues={{}} validationSchema={schema} innerRef={formikRef} onSubmit={submit}
            validateOnChange={false}>
            <BlockUi blocking={cargando !== EstadoCargando.Listo} >
                        <MyForm blocking={true}>
                            {mensajeErrorAlert && (<Alert variant="danger">{mensajeErrorAlert}</Alert>)}
                            <Tabs id="tabsEditarCaratula" activeKey={tabActual ?? 'principal'} onSelect={eventKey => updateTabActual(eventKey)}>
                                <Tab eventKey="principal" title="Datos">
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <MySelect name="CodigoSubregimen" label="Subregimen" getOptionLabel={optionLabelConCodigo}
                                                options={() => api.getSubregimenes().then(convertirDatosGenericosAOption)}
                                                onValueChange={onSubregimenChange} isDisabled={caratula?.Bloqueada}></MySelect>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <MyFormControl type="text" name="IdentificadorExterno"
                                                label="Identificador Externo" onValueChange={eventoHuboCambios}
                                                disabled={caratula?.Bloqueada || camposSubregimen?.txtIdExterno}></MyFormControl>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <MySelect name="CodigoAduana" label="Aduana"
                                                getOptionLabel={optionLabelConCodigo}
                                                onValueChange={eventoHuboCambios}
                                                options={() => api.getAduanas().then(convertirDatosGenericosAOption)}
                                                isDisabled={caratula?.Bloqueada}></MySelect>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <MySelect name="CodigoAduanaSalida" label="Aduana Salida"
                                                getOptionLabel={optionLabelConCodigo} onValueChange={eventoHuboCambios}
                                                isDisabled={caratula?.Bloqueada || camposSubregimen?.txtAduanaSalida}
                                                options={() => api.getAduanas().then(convertirDatosGenericosAOption)}></MySelect>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <MySelect name="CodigoPaisDestino" label="Pais Proc/Destino (Caratula)"
                                                getOptionLabel={optionLabelConCodigo} onValueChange={eventoHuboCambios}
                                                isDisabled={caratula?.Bloqueada || camposSubregimen?.txtPaisDestinoCaratula}
                                                options={() => api.getPaises().then(convertirDatosGenericosAOption)}></MySelect>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <MySelect name="CodigoPaisFacturacion" label="Pais Facturación"
                                                getOptionLabel={optionLabelConCodigo} onValueChange={eventoHuboCambios}
                                                isDisabled={caratula?.Bloqueada || camposSubregimen?.txtPaisFacturacion}
                                                options={() => api.getPaises().then(convertirDatosGenericosAOption)}></MySelect>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <MySelectConOptionABM name="CuitDespachante" label="Despachante"
                                                getOptionLabel={optionLabelConCodigo} onValueChange={eventoHuboCambios}
                                                isDisabled={caratula?.Bloqueada || camposSubregimen?.txtCuitDespachante}
                                                options={optionsDespachantes}
                                                labelOptionABM="Nuevo despachante..."
                                                onSelectABM={() => updateEstadoModalDespachantes({ tipo: 'mostrar' })}></MySelectConOptionABM>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <MySelectConOptionABM name="CuitImportador" label="Cliente"
                                                getOptionLabel={optionLabelConCodigo} isDisabled={caratula?.Bloqueada || camposSubregimen?.txtCuitImpoExpo}
                                                options={optionsImportadores}
                                                labelOptionABM="Nuevo cliente..."
                                                onSelectABM={() => updateEstadoModalImportadores({ tipo: 'mostrar' })}
                                                onValueChange={async (option) => {
                                                    eventoHuboCambios();
                                                    let cuitImportador = option as string | null | undefined;
                                                    await onCuitImportadorChange(cuitImportador);
                                                }}></MySelectConOptionABM>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <MyFormControl type="text" name="Anexo" label="Anexo" onValueChange={eventoHuboCambios}
                                                disabled={caratula?.Bloqueada || camposSubregimen?.txtCodigoVendedor} />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <MySelectConOptionABM name="CodigoVendedor" label="Vendedor"
                                                getOptionLabel={optionLabelConCodigo} onValueChange={eventoHuboCambios}
                                                isDisabled={caratula?.Bloqueada || camposSubregimen?.txtCodigoVendedor}
                                                options={optionsVendedores}
                                                labelOptionABM="Nuevo vendedor..."
                                                onSelectABM={() => updateEstadoModalVendedores({ tipo: 'mostrar' })}></MySelectConOptionABM>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <MyFormControl name="TotalFob" type="number" label="Total Factura"
                                                onValueChange={eventoHuboCambios}
                                                onValueChangeEnHook={valor => {
                                                    try {
                                                        let montoFob = new Decimal(valor);
                                                        let tipoCambio = new Decimal(formikRef.current?.values.TipoCambioFob);
                                                        if (tipoCambio.comparedTo(0) === 0) {
                                                            tipoCambio = new Decimal(1);
                                                        }
                                                        updateDolaresFob(toFixedDecimal(montoFob.mul(tipoCambio), 2));
                                                        calcularMontoSeguroSegunPorcentaje();
                                                    } catch (error) {
                                                    }
                                                }} disabled={caratula?.Bloqueada}></MyFormControl>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <MySelect name="CodigoDivisaFob" label="Divisa"
                                                getOptionLabel={optionLabelConCodigo}
                                                onValueChange={onMonedaChange('TipoCambioFob')}
                                                options={() => api.getMonedas().then(convertirDatosGenericosAOption)}
                                                isDisabled={caratula?.Bloqueada} />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <MyFormControl name="TipoCambioFob" type="number" label="Pase a Dolar"
                                                onValueChange={eventoHuboCambios}
                                                onBlur={onTipoCambioFobBlur} disabled={caratula?.Bloqueada}></MyFormControl>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label htmlFor="dolaresFob">Importe en Dolares</Form.Label>
                                            <Form.Control id="dolaresFob" type="text" disabled plaintext value={dolaresFob ?? ''}></Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <MyFormControl name="GastosAnterioresFob" type="number"
                                                label="Gastos Anteriores FOB" onValueChange={eventoHuboCambios}
                                                disabled={caratula?.Bloqueada || camposSubregimen?.txtGastosAnterioresFob}></MyFormControl>
                                        </Form.Group>
                                        <Form.Group as={Col} xs={3}>
                                            <Form.Row>
                                                <Form.Group as={Col}>
                                                    <MyFormControl name="AjusteFob" type="number"
                                                        label="Ajuste FOB" allowNegative
                                                        onValueChange={eventoHuboCambios}
                                                        disabled={estadoCodigosProrrateo.codigos.length > 0 || caratula?.Bloqueada || camposSubregimen?.txtAjusteFob}></MyFormControl>
                                                </Form.Group>
                                                <Form.Group as={Col} className="d-flex align-items-end">
                                                    <Button className="w-100" onClick={() => updateTabActual('codigosProrrateo')}>Codigos</Button>
                                                </Form.Group>
                                            </Form.Row>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <MyFormControl name="Flete" type="number" label="Flete" disabled={caratula?.Bloqueada || camposSubregimen?.txtMontoFlete}
                                                onValueChange={eventoHuboCambios} onValueChangeEnHook={(valor: any) => {
                                                    try {
                                                        let montoFlete = new Decimal(valor);
                                                        let tipoCambio = new Decimal(formikRef.current?.values.TipoCambioFlete);
                                                        if (tipoCambio.comparedTo(0) === 0) {
                                                            tipoCambio = new Decimal(1);
                                                        }
                                                        updateDolaresFlete(toFixedDecimal(montoFlete.mul(tipoCambio), 2));
                                                        calcularMontoSeguroSegunPorcentaje();
                                                    } catch (error) {
                                                    }
                                                }}></MyFormControl>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <MySelect name="CodigoDivisaFlete" label="Divisa Flete"
                                                getOptionLabel={optionLabelConCodigo}
                                                onValueChange={onMonedaChange('TipoCambioFlete')}
                                                isDisabled={caratula?.Bloqueada || camposSubregimen?.txtMontoFlete}
                                                options={() => api.getMonedas().then(convertirDatosGenericosAOption)} />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <MyFormControl name="TipoCambioFlete" type="number" label="Pase a Dolar Flete"
                                                onBlur={onTipoCambioFleteBlur} onValueChange={eventoHuboCambios}
                                                disabled={caratula?.Bloqueada || camposSubregimen?.txtMontoFlete}></MyFormControl>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label htmlFor="dolaresFlete">Importe Flete en Dolares</Form.Label>
                                            <Form.Control id="dolaresFlete" type="text" disabled plaintext value={dolaresFlete ?? ''}></Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col}></Form.Group>
                                        <Form.Group as={Col} xs={3}>
                                            <MyFormControl name="AjusteIncluir" type="number" label="Ajuste Incluir"
                                                onValueChange={eventoHuboCambios}
                                                disabled={caratula?.Bloqueada || camposSubregimen?.txtAjusteIncluir}></MyFormControl>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <MyFormControl name="Seguro" type="number" label="Seguro"
                                                onValueChange={eventoHuboCambios} onValueChangeEnHook={(valor: any) => {
                                                    try {
                                                        let montoSeguro = new Decimal(valor);
                                                        let tipoCambio = new Decimal(formikRef.current?.values.TipoCambioFob);
                                                        if (tipoCambio.comparedTo(0) === 0) {
                                                            tipoCambio = new Decimal(1);
                                                        }
                                                        updateDolaresSeguro(toFixedDecimal(montoSeguro.mul(tipoCambio), 2));
                                                    } catch (error) {
                                                    }
                                                }} disabled={caratula?.Bloqueada || camposSubregimen?.txtMontoSeguro}></MyFormControl>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <MySelect name="CodigoDivisaSeguro" label="Divisa Seguro"
                                                getOptionLabel={optionLabelConCodigo}
                                                onValueChange={onMonedaChange('TipoCambioSeguro')}
                                                isDisabled={caratula?.Bloqueada || camposSubregimen?.txtMontoSeguro}
                                                options={() => api.getMonedas().then(convertirDatosGenericosAOption)} />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <MyFormControl name="TipoCambioSeguro" type="number" label="Pase a Dolar Seguro"
                                                onBlur={onTipoCambioSeguroBlur} onValueChange={eventoHuboCambios}
                                                disabled={caratula?.Bloqueada || camposSubregimen?.txtMontoSeguro}></MyFormControl>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label htmlFor="dolaresSeguro">Importe Seguro en Dolares</Form.Label>
                                            <Form.Control id="dolaresSeguro" type="text" disabled plaintext value={dolaresSeguro ?? ''}></Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <MyFormControl name="GastosPosterioresFob" type="number" label="Gastos Posteriores FOB"
                                                onValueChange={eventoHuboCambios} disabled={caratula?.Bloqueada || camposSubregimen?.txtGastosPosterioresFob}></MyFormControl>
                                        </Form.Group>
                                        <Form.Group as={Col} xs={3}>
                                            <MyFormControl name="AjusteDeducir" type="number" label="Ajuste Deducir"
                                                onValueChange={eventoHuboCambios} disabled={caratula?.Bloqueada || camposSubregimen?.txtAjusteDeducir}></MyFormControl>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <MyFormControl name="PorcentajeSeguro" type="number" label="% Seguro"
                                                onValueChange={eventoHuboCambios}
                                                onValueChangeEnHook={calcularMontoSeguroSegunPorcentaje}
                                                disabled={caratula?.Bloqueada || camposSubregimen?.txtMontoSeguro}></MyFormControl>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <MyFormControl name="TipoCambioDolar" disabled={caratula?.Bloqueada} onValueChange={eventoHuboCambios}
                                                type="number" label="Tipo Cambio Dolar"></MyFormControl>
                                        </Form.Group>
                                        <Form.Group as={Col} className="d-flex align-items-end">
                                            <Button className="w-100" disabled={caratula?.Bloqueada} onClick={cargarCotizacionesClick}>Cargar cotizaciones</Button>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                        </Form.Group>
                                        <Form.Group as={Col} xs={3}>
                                            <MyFormControl name="DCKgNeto" type="number" label="Kg Neto"
                                                onValueChange={eventoHuboCambios}
                                                disabled={caratula?.Bloqueada || camposSubregimen?.txtKgNeto}></MyFormControl>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} xs={9}>
                                            <MySelect name="CondicionVenta" label="Condición Venta"
                                                getOptionLabel={optionLabelConCodigo} onValueChange={eventoHuboCambios}
                                                isDisabled={caratula?.Bloqueada || camposSubregimen?.txtCondicionVenta}
                                                options={() => api.getCondicionesVenta().then(convertirDatosGenericosAOption)} />
                                        </Form.Group>
                                        <Form.Group as={Col} xs={3}>
                                            <MyFormControl name="ComisionesExterior" type="number" label="Comisiones al Exterior"
                                                onValueChange={eventoHuboCambios} disabled={caratula?.Bloqueada || camposSubregimen?.txtComisionesAlExterior}></MyFormControl>
                                            <Form.Check id="checkEnviarComisionesAlExteriorSiEsCero" custom className="mt-2"
                                                label="Enviar si es cero" aria-label="Enviar comisiones al exterior si es cero" checked={enviarComisionesExteriorSiEsCero}
                                                onChange={(e: any) => updateEnviarComisionesExteriorSiEsCero(e.target.checked)}></Form.Check>
                                        </Form.Group>
                                    </Form.Row>
                                </Tab>
                                <Tab eventKey="datos" title="Datos generales">
                                    <br></br>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <div className="mb-2">
                                                <MyFormCheck name="ForzarDescripcionMercaderia" disabled={caratula?.Bloqueada || camposSubregimen?.checkForzarDescripcionMercaderia}
                                                    label="Forzar descripción de mercadería en todos los articulos" onCheckedChange={eventoHuboCambios}></MyFormCheck>
                                            </div>
                                            <MyTextarea name="DescripcionMercaderia" label="Descripción Mercadería" hideLabel onValueChange={eventoHuboCambios} disabled={caratula?.Bloqueada || camposSubregimen?.checkForzarDescripcionMercaderia}></MyTextarea>
                                        </Form.Group>
                                    </Form.Row>
                                    
                                    <Form.Row style={{background:'#DCDFDF', borderStyle:'solid', borderColor:'black', borderWidth:1, paddingTop:7, marginBottom:3}}>
                                        <Form.Group as={Col} className="col-sm-1">
                                            <MyFormControl type="text" name="ForzarStandard" label="Standard" onValueChange={eventoHuboCambios} disabled={caratula?.Bloqueada || camposSubregimen?.txtStandard}></MyFormControl>
                                        </Form.Group>
                                        <Form.Group as={Col} className="col-sm-1">
                                            <MyFormControl type="text" name="ForzarRegalias" label="Regalias" onValueChange={eventoHuboCambios} disabled={caratula?.Bloqueada || camposSubregimen?.txtRegalias}></MyFormControl>
                                        </Form.Group>
                                        <Form.Group as={Col}  className="col-sm-2">
                                            <MyFormControl type="date" name="FechaEmbarqueAprox" label="Fecha embarque aprox." onValueChange={eventoHuboCambios} disabled={caratula?.Bloqueada || camposSubregimen?.dateFechaEmbarqueAprox}></MyFormControl>
                                        </Form.Group>
                                        <Form.Group as={Col}  className="col-sm-2">
                                            <MyFormControl type="date" name="FechaArriboAprox" label="Fecha arribo aprox." onValueChange={eventoHuboCambios} disabled={caratula?.Bloqueada || camposSubregimen?.dateFechaArriboAprox}></MyFormControl>
                                        </Form.Group>
                                        <Form.Group as={Col} className="col-sm-2">
                                            <MyFormControl type="date" name="FechaGiro" label="Fecha Giro Div." onValueChange={eventoHuboCambios} disabled={caratula?.Bloqueada || camposSubregimen?.dateFechaArriboAprox}></MyFormControl>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <MyFormControl type="number" name="SIMIPlazoGiro" label="Plazo Giro Div." onValueChange={eventoHuboCambios} disabled={caratula?.Bloqueada || camposSubregimen?.dateFechaArriboAprox}></MyFormControl>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <MyFormControl type="number" name="MontoGiradoDOL" label="MONTOGIRADODOL" onValueChange={eventoHuboCambios} disabled={caratula?.Bloqueada || camposSubregimen?.dateFechaArriboAprox}></MyFormControl>
                                        </Form.Group>


                                    </Form.Row>








                                    <Form.Row style={{background:'#DCDFDF', borderStyle:'solid', borderColor:'black', borderWidth:1, paddingTop:7, marginTop:2, marginBottom:3}}>
                                        <Form.Group as={Col}>
                                            <div className="mb-2">
                                                <MyFormCheck labelOnLeft name="ForzarEstadoMercaderia" label="Forzar estado mercadería" onCheckedChange={eventoHuboCambios} disabled={caratula?.Bloqueada || camposSubregimen?.checkForzarEstadoMercaderia}></MyFormCheck>
                                            </div>
                                            <MySelect name="CodigoEstadoMercaderia" label="Estado mercadería" hideLabel isDisabled={caratula?.Bloqueada || camposSubregimen?.checkForzarEstadoMercaderia}
                                                getOptionLabel={optionLabelConCodigo} onValueChange={eventoHuboCambios}
                                                options={() => api.getEstadosMercaderia().then(convertirDatosGenericosAOption)}></MySelect>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label aria-hidden="true">País Origen/Prov.</Form.Label>
                                            <StyledFormCheck labelOnLeft name="ForzarPaisOrigen" label="Forzar" aria-label="Forzar país origen/prov." onCheckedChange={eventoHuboCambios} disabled={caratula?.Bloqueada || camposSubregimen?.checkForzarPaisOrigen}></StyledFormCheck>
                                            <MySelect name="CodigoPaisOrigen" label="País Origen" hideLabel isDisabled={caratula?.Bloqueada || camposSubregimen?.checkForzarPaisOrigen}
                                                getOptionLabel={optionLabelConCodigo} onValueChange={eventoHuboCambios}
                                                options={() => api.getPaises().then(convertirDatosGenericosAOption)}></MySelect>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label aria-hidden="true">País Procedencia/Destino</Form.Label>
                                            <StyledFormCheck labelOnLeft name="ForzarPaisDestino" label="Forzar" aria-label="Forzar país procedencia/destino" onCheckedChange={eventoHuboCambios} disabled={caratula?.Bloqueada || camposSubregimen?.checkForzarPaisDestinoItem}></StyledFormCheck>
                                            <MySelect name="CodigoPaisDestinoAForzar" label="País procedencia/destino" hideLabel isDisabled={caratula?.Bloqueada || camposSubregimen?.checkForzarPaisDestinoItem}
                                                getOptionLabel={optionLabelConCodigo} onValueChange={eventoHuboCambios}
                                                options={() => api.getPaises().then(convertirDatosGenericosAOption)}></MySelect>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <MySelect name="DCBancos" label="Banco interviniente"
                                                getOptionLabel={optionLabelConCodigo} onValueChange={eventoHuboCambios}
                                                isDisabled={caratula?.Bloqueada || camposSubregimen?.txtBancoInterviniente}
                                                options={() => api.getBancosTerceros().then(convertirDatosGenericosAOption)}></MySelect>
                                        </Form.Group>
                                    </Form.Row>


                                    <Form.Row style={{background:'#DCDFDF', borderStyle:'solid', borderColor:'black', borderWidth:1, paddingTop:7, marginTop:2, marginBottom:5}}>
                                        <Form.Group as={Col}>
                                            <MySelect name="OpcionesIVA" label="Opciones IVA" onValueChange={eventoHuboCambios}
                                                isDisabled={caratula?.Bloqueada || camposSubregimen?.cboOpcionesIVA}
                                                options={[{ value: 'NOCONTESTA', label: 'No contestar' }, { value: 'NO_VALIDA', label: 'Ninguno' },
                                                { value: 'IVAAD1', label: 'Uso/Consumo Particular' }, { value: 'IVAAD2', label: 'Importación definitiva de cosas muebles' }]}></MySelect>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <MySelect name="OpcionesGanancias" label="Opciones Ganancias" onValueChange={eventoHuboCambios}
                                                isDisabled={caratula?.Bloqueada || camposSubregimen?.cboOpcionesGanancias}
                                                options={[{ value: 'NOCONTESTA', label: 'No contestar' }, { value: 'NO_VALIDA', label: 'Ninguno' },
                                                { value: 'COMERC', label: 'Comercial' }, { value: 'BDEUSO', label: 'Bien de Uso' },
                                                { value: 'USOPAR', label: 'Uso Particular' }]}></MySelect>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <MySelect name="DomicilioEstampillado" label="Domicilio Estampillado"
                                                onValueChange={eventoHuboCambios}
                                                isDisabled={caratula?.Bloqueada || camposSubregimen?.txtDomicilioEstampillado}
                                                options={optionsDomicilioEstampillado}></MySelect>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <MyFormControl type="text" name="NroTransInter" label="Nro. Trans Inter" onValueChange={eventoHuboCambios}
                                                disabled={caratula?.Bloqueada || camposSubregimen?.txtNroTransInter}></MyFormControl>
                                        </Form.Group>
                                    </Form.Row>



















                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <MyFormControl type="date" name="FechaCierreVenta" label="Fecha Cierre Venta" onValueChange={eventoHuboCambios} disabled={caratula?.Bloqueada || camposSubregimen?.dateFechaCierreVenta}></MyFormControl>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <MyFormControl type="text" name="DepositoAlmacenamiento" label="Deposito almacenamiento" onValueChange={eventoHuboCambios} disabled={caratula?.Bloqueada || camposSubregimen?.txtDepAlmacenamiento}></MyFormControl>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <MyFormControl type="text" name="DatoComprador" label="Dato comprador" onValueChange={eventoHuboCambios} disabled={caratula?.Bloqueada || camposSubregimen?.txtDatoComprador}></MyFormControl>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <MyFormControl type="date" name="FechaEmbarque" label="Fecha Embarque" onValueChange={eventoHuboCambios} disabled={caratula?.Bloqueada || camposSubregimen?.dateFechaEmbarque}></MyFormControl>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <MyFormControl type="text" name="TerceroCuit" label="Tercero CUIT" onValueChange={eventoHuboCambios} disabled={caratula?.Bloqueada || camposSubregimen?.txtTerceroCUIT}></MyFormControl>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <MyFormControl type="text" name="AutorizDestSuspensiva" label="Autoriz. Dest. Suspensiva" onValueChange={eventoHuboCambios} disabled={caratula?.Bloqueada || camposSubregimen?.txtAutorizDestSuspensiva}></MyFormControl>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <MyFormControl type="date" name="FechaEmisionFactura" label="Fecha Factura" onValueChange={eventoHuboCambios} disabled={caratula?.Bloqueada || camposSubregimen?.txtFechaFactura}></MyFormControl>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <MySelect name="FacilidadPago" label="Facilidad de Pago" isDisabled={caratula?.Bloqueada || camposSubregimen?.cboFacilidadPago}
                                                options={[{ value: 'S', label: 'Sí' }, { value: 'N', label: 'No' }]} onValueChange={eventoHuboCambios}></MySelect>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <MySelect name="MotivoDestSuspensiva" label="Motivo Dest. Suspensiva"
                                                getOptionLabel={optionLabelConCodigo} isDisabled={caratula?.Bloqueada || camposSubregimen?.txtMotivoDestSuspensiva}
                                                options={() => api.getMotivosSuspensiva().then(convertirDatosGenericosAOption)} onValueChange={async valor => {
                                                    if (valor && !Array.isArray(valor)) {
                                                        try {
                                                            eventoHuboCambios();
                                                            let motivos = await api.getMotivosSuspensiva();
                                                            let motivo = motivos.find((item: any) => item.Codigo === valor);
                                                            if (motivo) {
                                                                formikRef.current?.setFieldValue('PlazoAutorizacion', motivo.Dias);
                                                            }
                                                        } catch (error) {
                                                            if (!api.isCancel(error)) {
                                                                console.error('Error al obtener plazo autorización', error);
                                                            }
                                                        }
                                                    }
                                                }} />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <MyFormControl type="number" name="PlazoAutorizacion" label="Plazo por días de autorización" onValueChange={eventoHuboCambios} disabled={caratula?.Bloqueada || camposSubregimen?.txtPlazoDiasAutorizacion}></MyFormControl>
                                        </Form.Group>
                                    </Form.Row>







                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <MyFormControl type="text" name="CuitTransporteTRM" label="Cuit Transportista" onValueChange={eventoHuboCambios}
                                                disabled={caratula?.Bloqueada || camposSubregimen?.txtCuitTransportistaTRM}></MyFormControl>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <MyFormControl type="text" name="VentaAlExterior" label="Venta Al Exterior" onValueChange={eventoHuboCambios}
                                                disabled={caratula?.Bloqueada || camposSubregimen?.txtVentaAlExterior}></MyFormControl>
                                        </Form.Group>
                                    </Form.Row>
                                </Tab>
                                <Tab eventKey="facturas" title="Facturas">
                                    <div className="mt-2">
                                        <MySelect name="PresenciaFacturas" label="Presencia de Facturas"
                                            options={[{ value: 'S', label: 'S' }, { value: 'N', label: 'N' }]}
                                            onValueChange={(option) => {
                                                eventoHuboCambios();
                                                let valor = option as string | null | undefined;
                                                updateEstadoFacturas({ tipo: 'setPresencia', valor });
                                            }} isDisabled={caratula?.Bloqueada}></MySelect>
                                        {estadoFacturas.presencia === 'S' && <div className="mt-2">
                                            <GrillaSync
                                                datos={estadoFacturas.facturas}
                                                campos={[{ propiedad: 'Indice', titulo: '', visible: false, clave: true },
                                                { propiedad: 'Valor', titulo: 'Valor' }]}
                                                eventoAgregar={() => updateEstadoFacturas({ tipo: 'ventanaCrear' })}
                                                deshabilitarBotonAgregar={caratula?.Bloqueada}
                                                eventoDetalle={(item: any) => updateEstadoFacturas({ tipo: 'ventanaModificar', valor: item.Indice })}
                                                deshabilitarEventoDetalle={caratula?.Bloqueada}
                                                eventoEliminar={(item: any) => {
                                                    updateMensajeDialogo("¿Está seguro que desea eliminar esta factura?");
                                                    dialogoRef.current!.mostrar()
                                                        .then(() => {
                                                            eventoHuboCambios();
                                                            updateEstadoFacturas({ tipo: 'deleteFactura', valor: item.Indice });
                                                        }).catch(() => { });
                                                }} deshabilitarBotonEliminar={caratula?.Bloqueada} mostrarFormCambiarPagina={false} />
                                        </div>}
                                    </div>
                                </Tab>
                                <Tab eventKey="infComp" title="Información Complementaria">
                                    <div className="mt-2">
                                        <GrillaSync datos={estadoInfComps.infComps}
                                            campos={[{ propiedad: 'Codigo', titulo: 'Codigo Inf. Comp.', clave: true },
                                            { propiedad: 'Valor', titulo: 'Valor' }]}
                                            eventoAgregar={() => updateEstadoInfComps({ tipo: 'ventanaCrear' })}
                                            deshabilitarBotonAgregar={caratula?.Bloqueada}
                                            eventoDetalle={(item: any) => updateEstadoInfComps({ tipo: 'ventanaModificar', valor: item })}
                                            deshabilitarEventoDetalle={caratula?.Bloqueada}
                                            eventoEliminar={(item: any) => {
                                                updateMensajeDialogo("¿Está seguro que desea eliminar esta información complementaria?");
                                                dialogoRef.current!.mostrar()
                                                    .then(() => {
                                                        eventoHuboCambios();
                                                        updateEstadoInfComps({ tipo: 'deleteInfComp', valor: item.Codigo });
                                                    }).catch(() => { });
                                            }} deshabilitarBotonEliminar={caratula?.Bloqueada} mostrarFormCambiarPagina={false} /></div>
                                </Tab>
                                <Tab eventKey="codigosProrrateo" title="Codigos Prorrateo">
                                    <div className="mt-2">
                                        <GrillaSync datos={estadoCodigosProrrateo.codigos}
                                            campos={[{ propiedad: 'Codigo', titulo: 'Codigo', clave: true },
                                            { propiedad: 'Descripcion', titulo: 'Descripción' }, { propiedad: 'Valor', titulo: 'Valor', tipo: TipoCampo.Number }]}
                                            eventoAgregar={() => updateEstadoCodigosProrrateo({ tipo: 'ventanaCrear' })}
                                            deshabilitarBotonAgregar={caratula?.Bloqueada}
                                            eventoDetalle={(item: any) => updateEstadoCodigosProrrateo({ tipo: 'ventanaModificar', valor: item })}
                                            deshabilitarEventoDetalle={caratula?.Bloqueada}
                                            eventoEliminar={(item: any) => {
                                                updateMensajeDialogo("¿Está seguro que desea eliminar este codigo de prorrateo?");
                                                dialogoRef.current!.mostrar()
                                                    .then(() => {
                                                        eventoHuboCambios();
                                                        updateEstadoCodigosProrrateo({ tipo: 'deleteCodigo', valor: item.Codigo });
                                                    }).catch(() => { });
                                            }} deshabilitarBotonEliminar={caratula?.Bloqueada} mostrarFormCambiarPagina={false} /></div>
                                </Tab>
                            </Tabs>
                            <Button variant="danger" className="mt-2 mb-2" onClick={() => {
                                disableRedirectEventCallback();
                                history.goBack();
                            }}>Cancelar</Button>
                            <Button disabled={caratula?.Bloqueada} onClick={async () => {
                                let promesa = formikRef.current?.submitForm() as unknown as Promise<boolean>;
                                if (await promesa) {
                                    disableRedirectEventCallback();
                                    history.replace('/caratulas/' + encodeURIComponent(interno));
                                }
                            }} className="mt-2 ml-2 mb-2">Guardar</Button>
                        </MyForm>

            </BlockUi>

        </Formik>
        <MyModal show={estadoFacturas.creando || estadoFacturas.modificando}
            onHide={() => updateEstadoFacturas({ tipo: 'esconderVentana' })}>
            <Modal.Dialog>
                <Modal.Header closeButton>
                    <h2>{estadoFacturas.creando ? 'Crear Factura' : 'Modificar Factura'}</h2>
                </Modal.Header>
                <Modal.Body>
                    <Formik innerRef={formikFacturasRef} validationSchema={Yup.object({
                        'Valor': Yup.string().nullable().required('Debe ingresar la factura')
                            .test('nro-factura-expo', 'El numero de factura de una exportacion debe tener el formato xxxxx-xxxxxxxx donde x es un digito', valor => {
                                if (subregimen?.Tipo === 'E') {
                                    return /^[0-9]{5}-[0-9]{8}$/.test(valor ?? '');
                                }
                                return true;
                            })
                    })} initialValues={{
                        'Valor': estadoFacturas.indiceModificando !== null && estadoFacturas.indiceModificando !== undefined ? estadoFacturas.facturas[estadoFacturas.indiceModificando].Valor : '',
                    }} onSubmit={(nuevoItem: { Valor: string }, actions) => {
                        eventoHuboCambios();
                        updateEstadoFacturas({ tipo: 'insertFactura', valor: nuevoItem.Valor });
                        actions.setSubmitting(false);
                    }}>
                        <MyForm>
                            <Form.Group>
                                {subregimen?.Tipo === 'E'
                                    ? <MyMaskedFormControl autoFocus hideLabel label="Factura" name="Valor" mask="99999-99999999"></MyMaskedFormControl>
                                    : <MyFormControl autoFocus type="text" hideLabel label="Factura" name="Valor"></MyFormControl>}
                            </Form.Group>
                        </MyForm>
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => updateEstadoFacturas({ tipo: 'esconderVentana' })}>
                        Cancelar
                    </Button>
                    <Button onClick={() => formikFacturasRef.current?.submitForm()}>
                        Ingresar
                    </Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={estadoInfComps.creando || estadoInfComps.modificando}
            onHide={() => updateEstadoInfComps({ tipo: 'esconderVentana' })}>
            <Modal.Dialog>
                <Modal.Header closeButton>
                    <h2>{estadoInfComps.creando ? 'Agregar Inf. Complementaria de Caratula' : 'Modificar Inf. Complementaria de Caratula'}</h2>
                </Modal.Header>
                <Modal.Body>
                    <Formik innerRef={formikInfCompRef} validationSchema={Yup.object({
                        'Codigo': Yup.string().nullable().required('Debe seleccionar el codigo de la información complementaria'),
                        'Valor': Yup.string().nullable().required('Debe ingresar el valor').max(40, 'El valor debe tener 40 caracteres o menos')
                    })} initialValues={{
                        'Codigo': estadoInfComps.valorModificando?.Codigo ?? '',
                        'Valor': estadoInfComps.valorModificando?.Valor ?? '',
                    }} onSubmit={(nuevaInfComp: { Codigo: string, Valor?: string }, actions) => {
                        let infCompsExistentes = estadoInfComps.infComps.map((infComp: any) => infComp.Codigo);
                        if (estadoInfComps.modificando) {
                            let indice = infCompsExistentes.indexOf(estadoInfComps.valorModificando.Codigo);
                            if (indice > -1) {
                                infCompsExistentes.splice(indice, 1);
                            }
                        }
                        if (infCompsExistentes.includes(nuevaInfComp.Codigo)) {
                            actions.setFieldError('Codigo', `La inf. complementaria con codigo ${nuevaInfComp.Codigo} ya fue ingresada`);
                        } else {
                            eventoHuboCambios();
                            updateEstadoInfComps({ tipo: 'insertInfComp', valor: nuevaInfComp });
                        }
                        actions.setSubmitting(false);
                    }}>
                        <MyForm>
                            <Form.Group>
                                <MySelect name="Codigo" label="Codigo Inf. Complementaria"
                                    options={getOptionsInformacionComplementaria}
                                    getOptionLabel={(option: SelectOption) => option.value}></MySelect>
                            </Form.Group>
                            <Form.Group>
                                <MyFormControl type="text" name="Valor" label="Valor"></MyFormControl>
                            </Form.Group>
                        </MyForm>
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => updateEstadoInfComps({ tipo: 'esconderVentana' })}>
                        Cancelar
                    </Button>
                    <Button onClick={() => formikInfCompRef.current?.submitForm()}>
                        Ingresar
                    </Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={estadoCodigosProrrateo.creando || estadoCodigosProrrateo.modificando}
            onHide={() => updateEstadoCodigosProrrateo({ tipo: 'esconderVentana' })}>
            <Modal.Dialog>
                <Modal.Header closeButton>
                    <h2>{estadoCodigosProrrateo.creando ? 'Crear Codigo Prorrateo' : 'Modificar Codigo Prorrateo'}</h2>
                </Modal.Header>
                <Modal.Body>
                    <Formik innerRef={formikCodigosProrrateoRef} validationSchema={Yup.object({
                        'Codigo': Yup.string().nullable().required('Debe ingresar el codigo'),
                        'Descripcion': Yup.string().nullable(),
                        'Valor': Yup.number().nullable().required('Debe ingresar el valor').typeError('El valor debe ser un número')
                    })} initialValues={{
                        'Codigo': estadoCodigosProrrateo.valorModificando?.Codigo ?? '',
                        'Descripcion': estadoCodigosProrrateo.valorModificando?.Descripcion ?? '',
                        'Valor': estadoCodigosProrrateo.valorModificando?.Valor ?? '',
                    }} onSubmit={(nuevoCodigo: { Codigo: string, Descripcion?: string, Valor: number }, actions) => {
                        let codigosExistentes = estadoCodigosProrrateo.codigos.map((item: any) => item.Codigo);
                        if (estadoCodigosProrrateo.modificando) {
                            let indice = codigosExistentes.indexOf(estadoCodigosProrrateo.valorModificando.Codigo);
                            if (indice > -1) {
                                codigosExistentes.splice(indice, 1);
                            }
                        }
                        if (codigosExistentes.includes(nuevoCodigo.Codigo)) {
                            actions.setFieldError('Codigo', `El codigo ${nuevoCodigo.Codigo} ya fue ingresado`);
                        } else {
                            eventoHuboCambios();
                            updateEstadoCodigosProrrateo({ tipo: 'insertCodigo', valor: nuevoCodigo });
                        }
                        actions.setSubmitting(false);
                    }}>
                        <MyForm>
                            <Form.Group>
                                <MyFormControl type="text" name="Codigo" label="Codigo"></MyFormControl>
                            </Form.Group>
                            <Form.Group>
                                <MyFormControl type="text" name="Descripcion" label="Descripción"></MyFormControl>
                            </Form.Group>
                            <Form.Group>
                                <MyFormControl type="number" name="Valor" label="Valor" allowNegative></MyFormControl>
                            </Form.Group>
                        </MyForm>
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => updateEstadoCodigosProrrateo({ tipo: 'esconderVentana' })}>
                        Cancelar
                    </Button>
                    <Button onClick={() => formikCodigosProrrateoRef.current?.submitForm()}>
                        Ingresar
                    </Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <ModalImportadores mostrar={estadoModalImportadores.abierto}
            eventoCerrarModal={() => updateEstadoModalImportadores({ tipo: 'cerrar' })}
            eventoSeleccionarImportador={(item: any) => updateEstadoModalImportadores({ tipo: 'cerrar', valor: item.CUIT })}></ModalImportadores>
        <MyModal show={estadoModalVendedores.abierto} onHide={() => updateEstadoModalVendedores({ tipo: 'cerrar' })}>
            <Modal.Dialog size="xl">
                <Modal.Body>
                    <InnerRouter initialRoute="/vendedores">
                        <Switch>
                            <Route path="/vendedores">
                                <RutasVendedor eventoSeleccionarVendedor={(item: any) => updateEstadoModalVendedores({ tipo: 'cerrar', valor: item.Codigo })}></RutasVendedor>
                            </Route>
                        </Switch>
                    </InnerRouter>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => updateEstadoModalVendedores({ tipo: 'cerrar' })}>Cerrar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={estadoModalDespachantes.abierto} onHide={() => updateEstadoModalDespachantes({ tipo: 'cerrar' })}>
            <Modal.Dialog size="xl">
                <Modal.Body>
                    <InnerRouter initialRoute="/despachantes">
                        <Switch>
                            <Route path="/despachantes">
                                <RutasDespachante eventoSeleccionarDespachante={(item: any) => updateEstadoModalDespachantes({ tipo: 'cerrar', valor: item.CUIT })}></RutasDespachante>
                            </Route>
                        </Switch>
                    </InnerRouter>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => updateEstadoModalDespachantes({ tipo: 'cerrar' })}>Cerrar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <DialogoConfirmar ref={dialogoRef} mensaje={mensajeDialogo} textoBotonCancelar="No" textoBotonConfirmar="Sí" />
    </>);
}